import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import PageLayout from "../components/PageLayout";

const ProfilePage = () => {
  const { user } = useAuth0();

  if (!user) {
    return null;
  }

  return (
    <PageLayout>
      <h1>Profile Page</h1>
      <div>
        <div>
          <div>
            <img src={user.picture} alt="Profile" className="profile__avatar" />
            <div>
              <h2>{user.name}</h2> (<span>{user.email}</span>)
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default ProfilePage;
