import React from "react";
import { withAuthenticationRequired } from "@auth0/auth0-react";
import LoadingSpinner from "./common/LoadingSpinner";

const AuthGuard = ({ component, currentTab, subTab }) => {
  const Component = withAuthenticationRequired(component, {
    onRedirecting: () => <LoadingSpinner />,
  });

  return <Component {...{ currentTab, subTab }} />;
};

export default AuthGuard;
