import * as React from "react";
import { Box, Button, TextField, Typography } from "@mui/material";

import ModalContainer from "./common/Modal";

const AddCollectionModal = ({ open, onClose, onSubmit }) => {
  const [data, setData] = React.useState("");

  const handleChange = (e) => {
    setData(e.target.value);
  };

  return (
    <ModalContainer
      open={open}
      onClose={onClose}
      ariaLabelledBy="add-collection-modal"
    >
      <Box>
        <Typography variant="h5" component="h2">
          New Collection
        </Typography>
        <TextField
          label="Name"
          sx={{ margin: "1rem 0" }}
          value={data}
          onChange={handleChange}
          fullWidth
        />
        <Button
          variant="contained"
          disabled={data.length < 4}
          onClick={() => onSubmit(data)}
          fullWidth
        >
          Save
        </Button>
      </Box>
    </ModalContainer>
  );
};

export default AddCollectionModal;
