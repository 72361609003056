import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";

import PageLayout from "../components/PageLayout";
import Header from "../components/Header";

const CallbackPage = () => {
  const { error } = useAuth0();

  if (error) {
    return (
      <PageLayout>
        <h1>Error</h1>
      </PageLayout>
    );
  }

  return (
    <div className="page-layout">
      <Header />
    </div>
  );
};

export default CallbackPage;
