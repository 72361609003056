import * as React from "react";
import { Box, Typography } from "@mui/material";

import useCollectionsContext from "../../context/CollectionsContext";
import { isProd } from "../../utils/selectors";

const DataBanner = () => {
  const { dataEnv } = useCollectionsContext();

  return (
    <Box
      sx={{
        backgroundColor: isProd(dataEnv) ? "warning.light" : "info.light",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "24px",
      }}
    >
      <Typography
        sx={{
          textTransform: "uppercase",
          fontSize: "12px",
          fontWeight: "700",
        }}
      >
        -- {dataEnv} --
      </Typography>
    </Box>
  );
};

export default DataBanner;
