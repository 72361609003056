import * as React from "react";

import { Chip } from "@mui/material";

const classes = {
  default: {
    borderRadius: "3px",
    marginLeft: "10px",
    opacity: 0.88,

    "& > span": { color: "#fff", fontSize: "14px", marginTop: "3px" },
    "&:hover": {
      cursor: "pointer",
      opacity: 1,
    },
    "&:disabled": {
      cursor: "not-allowed",
    },
  },
  delete: {
    background: "red",
  },
  primary: {
    background: "green",
  },
};

const ToolbarButton = ({ type, disabled, label, onClick }) => {
  return (
    <Chip
      sx={{ ...classes.default, ...classes[type] }}
      {...{
        disabled,
        label,
        onClick,
      }}
    />
  );
};

export default ToolbarButton;
