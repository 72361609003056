import { TextField } from "@mui/material";

const SearchBar = ({ type, value, handleSearch }) => (
  <TextField
    label={`Search ${type} (min. 3 characters)`}
    size="small"
    variant="outlined"
    margin="normal"
    fullWidth
    value={value}
    onChange={handleSearch}
  />
);

export default SearchBar;
