import * as React from "react";
import { Box } from "@mui/material";

import CustomButton from "./common/CustomButton";
import { exportItems } from "../utils/fetch.utils";

const Export = ({ collectionId }) => {
  const handleExport = async () => {
    await exportItems(collectionId).then((blob) => {
      const now = Date.now("YYYYMMDD-HHmmss");
      const date = new Date(now).toISOString();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${collectionId}-${date}.csv`);
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);
    });
  };

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <CustomButton
        variant="contained"
        onClick={handleExport}
        label=" Export all Items"
      />
    </Box>
  );
};

export default Export;
