import * as React from "react";
import { Box } from "@mui/material";

import Import from "../../components/Import";
import Export from "../../components/Export";

const styling = {
  section: {
    marginTop: "1rem",
    paddingBottom: "2rem",
    borderBottom: "1px solid #eaeaea",
  },
};

const ImportExport = ({ collectionId }) => {
  return (
    <>
      <Box sx={styling.section}>
        <Export collectionId={collectionId} />
      </Box>
      <Box sx={styling.section}>
        <Import collectionId={collectionId} />
      </Box>
    </>
  );
};

export default ImportExport;
