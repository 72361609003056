import * as React from "react";
import { useParams } from "react-router-dom";
import { Box, Container } from "@mui/material";

import PageLayout from "../../components/PageLayout";
import LoadingSpinner from "../../components/common/LoadingSpinner";
import { H2 } from "../../components/common/typography";
import TabbedContent from "../../components/common/TabbedContent";
import Items from "./Items";
import Utils from "./Utils";
import Preview from "./Preview";
import ImportExport from "./ImportExport";

import useFetchCollection from "../../hooks/use-fetch-collection.hook";
import useCollectionsContext from "../../context/CollectionsContext";

const Collection = ({ currentTab = 0, subTab = 0 }) => {
  const { collectionId } = useParams();
  const { loading, current: currentColl } = useCollectionsContext();

  useFetchCollection(collectionId);

  return (
    <PageLayout>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <Container maxWidth="xl">
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              borderBottom: "1px solid #808080",
              marginBottom: "1rem",
            }}
          >
            <H2>{currentColl?.name || "Collection"}</H2>
          </Box>
          <TabbedContent
            {...{
              currentTab,
              content: [
                {
                  label: "Items",
                  path: `/collections/${collectionId}/items`,
                  component: <Items {...{ collectionId, currentColl }} />,
                },
                {
                  label: "Utilities",
                  path: `/collections/${collectionId}/utilities`,
                  component: <Utils {...{ collectionId, currentColl }} />,
                },
                {
                  label: "Preview & Code",
                  path: `/collections/${collectionId}/preview`,
                  component: <Preview {...{ collectionId, subTab }} />,
                },
                {
                  label: "Import & Export",
                  path: `/collections/${collectionId}/bulk`,
                  component: <ImportExport {...{ collectionId, subTab }} />,
                },
              ],
            }}
          />
        </Container>
      )}
    </PageLayout>
  );
};

export default Collection;
