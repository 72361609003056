import { useEffect } from "react";
import { getCollection } from "../utils/fetch.utils";
import useCollectionsContext from "../context/CollectionsContext";

export default function useFetchCollection(collectionId) {
  const { setCurrentCollection, setDataEnv } = useCollectionsContext();

  useEffect(() => {
    const fetchAndUpdate = async () => {
      const current = await getCollection(collectionId);
      setCurrentCollection(current);
      setDataEnv(current.mongoEnv);
    };

    fetchAndUpdate();
    // TODO: wrap setCurrentCollection in a useCallback to satisfy exahustive deps
    // eslint-disable-next-line
  }, [collectionId]);
}
