import * as React from "react";

import TableCell from "@mui/material/TableCell";
import { GoEye, GoTrashcan } from "react-icons/go";
import ToolbarButton from "../ToolbarButton";

const classes = {
  root: {
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
  },
};

const Tools = ({
  itemData,
  handleSelect,
  handleDelete,
  deleteDisabled = true,
}) => {
  // custom_keys don't have _ids
  const itemIdentifier = itemData._id || itemData.slug;

  return (
    <TableCell sx={classes.root}>
      <ToolbarButton
        {...{
          label: <GoEye />,
          type: "primary",
          disabled: !handleSelect,
          onClick: () => {
            handleSelect(itemIdentifier);
          },
        }}
      />
      <ToolbarButton
        {...{
          label: <GoTrashcan />,
          type: "delete",
          disabled: !handleDelete || deleteDisabled || itemData.published,
          onClick: () => {
            handleDelete(itemIdentifier);
          },
        }}
      />
    </TableCell>
  );
};

export default Tools;
