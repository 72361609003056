import * as React from "react";
import { Box, Modal } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 500,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const ModalContainer = ({ ariaLabelledBy, open, onClose, children }) => (
  <Modal {...{ open, onClose, "aria-labelledby": ariaLabelledBy }}>
    <Box sx={style}>{children}</Box>
  </Modal>
);

export default ModalContainer;
