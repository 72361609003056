import * as React from "react";

import Header from "./Header";

const PageLayout = ({ children }) => {
  return (
    <div>
      <Header />
      {children}
    </div>
  );
};

export default PageLayout;
