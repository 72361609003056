import React from "react";
import { Box, Typography } from "@mui/material";
import { FilePond } from "react-filepond"; // https://pqina.nl/filepond/docs/

import { H3 } from "./common/typography";

import { getFilePondServerSettings } from "../utils/fetch.utils";

import "filepond/dist/filepond.min.css";
import ImportSummary from "./ImportSummary";

const Import = ({ collectionId }) => {
  const [files, setFiles] = React.useState([]);
  const [importResult, setImportResult] = React.useState([]);
  const filePondServerSettings = getFilePondServerSettings(collectionId);

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <H3>Import Items</H3>
      <Typography>
        At the moment, the import feature is still in beta. In order to minimize
        errors, it is better to export your data first and use a copy of that
        csv and import an updated copy of that file below.
      </Typography>
      <Typography>
        We recommend removing all items that you do not want to edit from the
        file. Keeping only the new and/or updated items. The server will
        overwrite all items in the file, even if they are identical. The server
        WILL NOT delete any items.
      </Typography>
      <Typography>
        Do not remove the id of an existing item from the file. The server uses
        that to know whether to update an item or add a new one. New items won't
        have a value in there.{" "}
        <em>
          DO NOT manually add a value to the <code>_id</code> column.
        </em>
      </Typography>
      <Box
        sx={{
          border: "3px dashed #ccc",
          padding: "2rem 2rem 1rem",
          margin: "2rem 0 2rem",
        }}
      >
        <FilePond
          {...{
            files,
            name: "csvfile",
            maxFiles: 1,
            maxFileSize: "3MB",
            onupdatefiles: setFiles,
            labelIdle:
              'Drag & Drop your files or <span class="filepond--label-action">Browse</span>',
            credits: false,
            server: {
              url: filePondServerSettings.url,
              process: {
                headers: filePondServerSettings.headers,
                onload: (res) => {
                  setImportResult(JSON.parse(res).data);
                },
              },
            },
          }}
        />
      </Box>
      <ImportSummary importResult={importResult} />
    </Box>
  );
};

export default Import;
