import * as React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { Box } from "@mui/material";

import CustomButton from "../common/CustomButton";
import DataBanner from "./DataBanner";

const Header = () => {
  const { isAuthenticated, loginWithRedirect, logout, getAccessTokenSilently } =
    useAuth0();

  const navigate = useNavigate();

  React.useEffect(() => {
    const getAccessToken = async () => {
      const domain = process.env.REACT_APP_AUTH0_DOMAIN;

      try {
        const accessToken = await getAccessTokenSilently({
          authorizationParams: {
            audience: `https://${domain}/api/v2/`,
          },
        });
        localStorage.setItem("access_token", accessToken);
      } catch (e) {
        console.log(e.message);
      }
    };

    getAccessToken();
  }, [getAccessTokenSilently]);

  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/collections",
      },
    });
  };

  const handleLogout = () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  };

  return (
    <>
      <DataBanner />
      <Box
        sx={{
          backgroundColor: "primary.dark",
          height: "66px",
          display: "flex",
          alignItems: "center",
          justifyContent: "flex-end",
          padding: "0 2rem",
        }}
      >
        {!isAuthenticated ? (
          <CustomButton
            {...{
              isDisabled: false,
              label: "Login",
              sx: { margin: "0" },
              onClick: handleLogin,
            }}
          />
        ) : null}

        {isAuthenticated ? (
          <>
            <CustomButton
              sx={{ margin: "0 .5rem 0 0" }}
              onClick={() => navigate("/collections", { replace: true })}
              label="Collections"
            />
            <CustomButton
              {...{
                isDisabled: false,
                label: "Logout",
                sx: { margin: "0" },
                onClick: handleLogout,
              }}
            />
          </>
        ) : null}
      </Box>
    </>
  );
};

export default Header;
