import { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import useCollectionsContext from "../context/CollectionsContext";
import { getCollections } from "../utils/fetch.utils";

export default function useFetchCollections(searchTerm, pagination) {
  const { setCollections, setDataEnv } = useCollectionsContext();

  const { user } = useAuth0();

  const strLength = searchTerm.length || 0;

  useEffect(() => {
    // do not initiate a search if less than three characters
    if (strLength > 0 && strLength < 3) return;

    const fetchAndUpdate = async () => {
      const collections = await getCollections(searchTerm, pagination);
      setCollections({ documents: collections.data });
      setDataEnv(collections.mongoEnv);
    };

    fetchAndUpdate();
  }, [
    strLength,
    searchTerm,
    pagination,
    setCollections,
    setDataEnv,
    // adding user to the dependency array to trigger a refresh on login
    user.email_verified,
  ]);
}
