import * as React from "react";
import PageLayout from "../components/PageLayout";

const NotFoundPage = () => {
  return (
    <PageLayout>
      <h1>Not Found</h1>
    </PageLayout>
  );
};

export default NotFoundPage;
