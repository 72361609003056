import * as React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import LoadingSpinner from "./common/LoadingSpinner";
import AuthGuard from "./AuthGuard";
import Collections from "../pages/Collections";
import Collection from "../pages/Collection";
import ProfilePage from "../pages/ProfilePage";
import CallbackPage from "../pages/CallbackPage";
import NotFoundPage from "../pages/NotFoundPage";

const App = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Routes>
      <Route path="/" element={<Navigate to="/collections" replace />} />
      <Route
        path="/collections"
        element={<AuthGuard component={Collections} />}
      />

      <Route
        path="/collections/:collectionId/items"
        element={<AuthGuard component={Collection} currentTab={0} />}
      />

      <Route
        path="/collections/:collectionId/utilities"
        element={<AuthGuard component={Collection} currentTab={1} />}
      />

      <Route
        path="/collections/:collectionId/preview"
        element={<AuthGuard component={Collection} currentTab={2} subTab={0} />}
      />
      <Route
        path="/collections/:collectionId/preview/subcollection"
        element={<AuthGuard component={Collection} currentTab={2} subTab={1} />}
      />
      <Route
        path="/collections/:collectionId/preview/widget"
        element={<AuthGuard component={Collection} currentTab={2} subTab={2} />}
      />

      <Route
        path="/collections/:collectionId/bulk"
        element={<AuthGuard component={Collection} currentTab={3} />}
      />

      <Route path="/profile" element={<AuthGuard component={ProfilePage} />} />
      <Route path="/callback" element={<CallbackPage />} />
      <Route path="*" element={<NotFoundPage />} />
    </Routes>
  );
};

export default App;
